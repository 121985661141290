import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import bgImage from "../../assets/images/latest/bg_contact_form.jpg";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";
import { useMediaQuery } from "@mui/material";
import theme from "../../assets/theme";
import * as emailjs from "@emailjs/browser";

function Contacts() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => emailjs.init("pCJb3sg7eZwefJyg0"), []);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const [buttonText, setButtonText] = useState('Odeslat zprávu');
  const [buttonColor, setButtonColor] = useState('dark');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const isFormValid = formData.name && formData.phone && formData.email && formData.message;
    setIsButtonDisabled(!isFormValid);
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    setButtonText('Odesílání...');
    setButtonColor('secondary');

    const serviceId = "service_pb4coq9";
    const templateId = "template_no6x80c";
    try {
      await emailjs.send(serviceId, templateId, {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        message: formData.message,
      });
      setButtonText('Odesláno');
      setButtonColor('success');

      setFormData({
        name: '',
        phone: '',
        email: '',
        message: '',
      });
    } catch (error) {
      console.log(error);
      setButtonText('Chyba!');
      setButtonColor('error');
    }
  };

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }} mx={isMobile ? -2 : 0}>
      <Container sx={{ px: isMobile ? 0 : 6 }}>
        <Grid container item sx={{ px: isMobile ? 0 : 6 }}>
          <MKBox
            width="100%"
            bgColor="white"
            borderRadius={isMobile ? 0 : "xl"}
            shadow="xl"
            mb={isMobile ? 0 : 6}
            sx={{ overflow: "hidden" }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <MKBox component="form" p={2} onSubmit={handleSubmit}>
                  <MKBox px={{ xs: 2, sm: 3 }} py={{ xs: 2, sm: 6 }}>
                    <MKTypography variant="h2" mb={1}>
                      Napište nám!
                    </MKTypography>
                    <MKTypography variant="body1" color="text" mb={2}>
                      Rádi s vámi probereme vaše dotazy.
                    </MKTypography>
                  </MKBox>
                  <MKBox pt={0.5} pb={3} px={{ xs: 2, sm: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="Jméno"
                          placeholder="Vaše jméno"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="Telefonní číslo"
                          placeholder="Vaše telefonní číslo"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="Email"
                          placeholder="Váš email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="Vaše zpráva"
                          placeholder="Zpráva"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          multiline
                          rows={6}
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={6}
                      justifyContent="flex-end"
                      textAlign="right"
                      mt={3}
                    >
                      <MKButton
                        type="submit"
                        variant="gradient"
                        color={buttonColor}
                        disabled={isButtonDisabled}
                      >
                        {buttonText}
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                position="relative"
                px={0}
                sx={{
                  backgroundImage: ({
                                      palette: { gradients },
                                      functions: { rgba, linearGradient },
                                    }) =>
                    `${linearGradient(
                      rgba(gradients.secondary.main, 0.8),
                      rgba(gradients.secondary.state, 0.8)
                    )}, url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <MKBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  px={{ xs: 2, sm: 6 }}
                  py={{ xs: 6, lg: 6 }}
                >
                  <MKBox>
                    <MKTypography variant="h3" color="white" mb={1}>
                      Kontaktujte nás
                    </MKTypography>
                    <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                      Vyplňte formulář a náš tým se vám ozve do 24 hodin.
                    </MKTypography>
                    <MKBox display="flex" p={1} alignItems="center">
                      <a href="tel:+420702160835" style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center" }}>
                        <PhoneIcon style={{ color: "white" }} />
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          +420 702 160 835
                        </MKTypography>
                      </a>
                    </MKBox>
                    <MKBox display="flex" p={1} alignItems="center">
                      <a href="mailto:lekvi@re-max.cz" style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center" }}>
                        <EmailIcon style={{ color: "white" }} />
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          lekvi@re-max.cz
                        </MKTypography>
                      </a>
                    </MKBox>
                    <MKBox display="flex" p={1} alignItems="center">
                      <a href="https://maps.google.com/?q=Pitterova 2855/13, 130 00 Praha 3" target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "none", display: "flex", alignItems: "center" }}>
                        <LocationOnIcon style={{ color: "white" }} />
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          Pitterova 2855/13, 130 00 Praha 3
                        </MKTypography>
                      </a>
                    </MKBox>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Contacts;
