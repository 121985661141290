import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import Box from "@mui/material/Box";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import remax_logo from '../../../assets/images/latest/selected/remax_lekvi_logo.png';

function Footer() {
  const date = new Date().getFullYear();

  return (
    <MKBox component="footer" py={4} sx={{ backgroundColor: "#344767" }} mx={-2}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <Box
              component="img"
              src={remax_logo}
              alt="RE/MAX Brand"
              sx={{ width: '100%', maxWidth: '120px', background: "transparent", mb: 2, mx: { xs: 'auto', md: 0 } }}
            />
            <MKTypography variant="h6" color="white" mb={1}>
              RE/MAX Lekvi
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
              Oficiální prodejce projektu
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Pitterova 2855/13, 130 00 Praha 3
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <AccessTimeIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> Otevírací doba: Po - Pá: 9:00 - 18:00
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> +420 702 160 835
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} /> lekvi@re-max.cz
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={4} textAlign={{ xs: 'center', md: 'left' }}>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              IČ: 24786501
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8} mb={1}>
              DIČ: CZ24786501
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={1}>
              &copy; {date} RE/MAX Lekvi. All rights reserved.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
